@import "styles/base";

.h1 {
  $padding: 16px;
  $font-size: 28px;
  $line-height: 32px;

  @include margin-classes();
  @include padding-classes();
  @include text-align-classes();
  @include text-color-classes();

  & {
    font-weight: 700;
    font-size: $font-size;
    line-height: $line-height;
  }

  &-subdued {
    color: color(ink, lighter)
  }

  &-error {
    color: color(red)
  }

  &-info {
    color: color(blue)
  }
}
