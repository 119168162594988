@import 'styles/base';

.tag {

  & {
    height: 30px;
    display: flex;
    font-size: 14px;
    padding: 0 13px;
    border: border();
    line-height: 14px;
    user-select: none;
    align-items: center;
    border-radius: 99999px;
    justify-content: center;
    background-color: color(white)
  }

  &-bullet {
    font-size: 18px;
    margin-top: -2px;
    margin-right: 7px;
    color: color(blue)
  }

  &-info {
    color: color(white);
    border-color: color(blue);
    background-color: color(blue);
  }

  &-info &-bullet {
    color: color(white)
  }

}
