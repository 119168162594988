@import 'styles/base';

.label {
  & {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px
  }

  &-subdued {
    color: color(ink, lighter)
  }

  &-error {
    color: color(red)
  }
}
