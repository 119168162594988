.container {
  &-relative {
    position: relative;
  }
  & {
    width: 1280px;
    margin: 0 auto;
    max-width: 100vw
  }
  @media (max-width: 415px) {
    width: 100%;
  }
}
