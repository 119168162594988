@import 'styles/base';

.text {
  $padding: 16px;
  $font-size: 14px;
  $line-height: 20px;

  @include margin-classes();
  @include padding-classes();
  @include text-color-classes();
  @include text-align-classes();

  & {
    display: inline;
    font-weight: 400;
    font-size: $font-size;
    line-height: $line-height;
    word-break: break-word;
    strong {
      font-weight: bold;
    }
  }

  &-nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-subdued {
    color: color(ink, lighter);
  }

  &-error {
    color: color(red);
  }

  &-bold {
    font-weight: 700;
  }

  &-underline {
    text-decoration: underline;
  }
}
