$shadows: (
  faint: (
    0 1px 0 0 rgba(22, 29, 37, 0.05),
  ),
  base: (
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15),
  ),
  deep: (
    0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px rgba(33, 43, 54, 0.08),
  ),
  layer: (
    0 31px 41px 0 rgba(32, 42, 53, 0.2),
    0 2px 16px 0 rgba(32, 42, 54, 0.08),
  ),
  transparent: 0 0 0 0 transparent,
);