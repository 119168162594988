@mixin bg-color-classes() {
  @each $color, $variants in $color-palette {
    @each $variant, $hex in $variants {
      $selector: #{$color};
      @if $variant != base {
        $selector: #{$color}-#{$variant};
      }

      &-#{$selector} {
        background-color: $hex
      }
    }
  }
}
