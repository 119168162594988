@import 'styles/base';

.checkbox-field {
  & {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 55px;
    width: 100%;
    margin: 5px;
  }

  &-label-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 36px
  }

  &-label {
    font-size: 12px;
    margin-top: 3px;
    margin-left: 5px;
  }

  &-error {
    font-size: 12px;
    margin-top: 3px;
    margin-left: 5px;
  }

  &-wrapper {
    & {
      display: flex;
      align-items: center;
    }
    input {
      margin-right: 8px
    }
  }

  &-error {
    font-size: 12px;
    margin-top: 3px;
    margin-left: 5px;
    color: #dc3545;
  }

  &:first-child {
    margin-left: 0
  }

  &:last-child {
    margin-right: 0
  }
}
