.time-calendar {
  & {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 200px;
    max-width: 100%;
    transition: all 1s;
  }
  &-isFetching {
    .time-calendar-days {
      position: relative;
      > div {
        justify-content: center;
        align-items: flex-start;
        min-width: 100%;
        padding-top: 15px;
        min-height: 237px;
      }
    }
  }
  &-days {
    overflow-x: auto;
    flex-grow: 1;
  }
  &-next {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hide-extra-days {
  width: 92px;
  .extra {
    display: none;
  }
  [data-id="button-more"] {
    max-width: 69px;
  }
}

