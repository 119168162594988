@import "./size-group.mixin";

@mixin text-align-classes() {
  &-justify {
    text-align: justify
  }

  &-right {
    text-align: right
  }

  &-left {
    text-align: left
  }

  &-center {
    text-align: center
  }
}
