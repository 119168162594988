@import 'styles/base';

.contact-item {

  &-link {
    color: color(blue)
  }

  &-link:hover {
    color: color(blue, dark)
  }

  &-icon svg {
    width: 18px;
    margin-top: 5px;
  }

}
