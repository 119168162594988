.form-field {
  & {
    width: 100%;
    margin: 8px
  }

  &:first-child {
    margin-left: 0
  }

  &:last-child {
    margin-right: 0
  }
}
