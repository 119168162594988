.navbar {

  $height: 64px;
  $background-color: color(white);

  & {
    width: 100%;
    display: flex;
    height: $height;
    align-items: center;
    justify-content: space-between;
    background-color: $background-color
  }

  &-left {
    flex: 1
  }

  @media (max-width: 1280px) {
    padding: 0 16px;
    width: auto;
  }
}
