@import 'styles/base';

.stepper {
  $width: 90%;

  & {
    height: 48px;
    margin: 16px auto;
  }

  &-steps {
    z-index: 1;
    width: $width;
    height: 48px;
    display: flex;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: space-between;
  }

  &-step {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    background-color: color(sky);
  }

  &-step::before {
    left: 50%;
    top: -100%;
    z-index: 1;
    width: 64px;
    display: block;
    font-size: 12px;
    color: color(sky);
    text-align: center;
    position: absolute;
    margin-top: -7px;
    content: attr(data-label);
    transform: translate(-50%, -50%);
  }

  &-step-active {
    background-color: color(blue);
  }

  &-step-active::before {
    color: color(blue)
  }

  &-line {
    width: $width;
    height: 2px;
    margin: 0 auto;
    margin-top: -8px;
    background-color: color(sky);
  }

  &-progress {
    height: 2px;
    margin-top: -8px;
    background-color: color(blue);
  }
}
