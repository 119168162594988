@import "styles/base";

.button {
  // Button
  $height: 36px;
  $cursor: pointer;
  $shadow: shadow();
  $border: border();
  $user-select: none;
  $radius: border-radius();
  $font-size: 14px;

  // Large
  $large-height: 44px;

  & {
    font-size: $font-size;
    white-space: nowrap;
    padding: 0 16px;
    display: flex;
    border: $border;
    cursor: $cursor;
    height: $height;
    box-shadow: $shadow;
    align-items: center;
    border-radius: $radius;
    justify-content: center;
    user-select: $user-select;
    font-size: 14px;
    line-height: 20px
  }

  &-large {
    height: $large-height
  }

  &-block {
    width: 100%
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default
  }

  &-link {
    & {
      box-shadow: none;
      color: color(blue);
      border-color: transparent;
      background-color: transparent
    }
    &:hover {
      color: color(blue, dark)
    }
    &:focus {
      outline: none;
      color: color(blue, dark);
      background-color: color(sky)
    }
    &[disabled]:hover {
      color: color(blue, dark)
    }
  }

  &-default {
    @include button-gradient(sky, lighter);
    color: color(ink) !important
  }

  &-success {
    @include button-gradient(green)
  }

  &-info {
    @include button-gradient(blue)
  }

  &-warning {
    @include button-gradient(orange)
  }
  svg {
    margin: 0 5px;
    width: 14px
  }
}
