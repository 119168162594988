.avatar {
  // default small
  $small: 32px;
  $medium: 40px;
  $large: 60px;
  $huge: 160px;

  & {
    display: flex;
    border-radius: $medium;
    width: $medium;
    height: $medium;
    background: #dae0e5 no-repeat center center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    img {
      object-fit: cover;
      width: $medium;
      height: $medium;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    .title {
      display: block;
    }
  }

  &-sm {
    border-radius: $small;
    width: $small;
    height: $small;
    img {
      width: $small;
      height: $small;
    }
  }

  &-md {
    border-radius: $medium;
    width: $medium;
    height: $medium;
    img {
      width: $medium;
      height: $medium;
    }
  }

  &-lg {
    border-radius: $large;
    width: $large;
    height: $large;
    img {
      width: $large;
      height: $large;
    }
  }

  &-3xl {
    border-radius: $huge;
    width: $huge;
    height: $huge;
    img {
      width: $huge;
      height: $huge;
    }
  }

  .title {
    display: none;
    background-color: rgba(#454f5b, 0.5);
    color: #fff;
    font-size: 12px;
    text-align: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 3px 0 9px 0;
  }
}
