@mixin property-position($property, $position, $value) {
  @if $position == default {
    #{$property}: $value;
  } @else if $position == x {
    #{$property}-left: $value;
    #{$property}-right: $value;
  } @else if $position == y {
    #{$property}-top: $value;
    #{$property}-bottom: $value;
  } @else {
    #{$property}-#{$position}: $value;
  }
}
