.image-upload-form {
  & {
    display: flex;
    align-items: center;
    flex-direction: column
  }

  &-avatar {
    position: relative;
    margin-bottom: 16px
  }

  &-avatar-uploading {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
    transform: translate(-50%,-50%);
    width: 64px;
    height: 64px;
  }
  &-avatar-uploading div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &-avatar-uploading div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  &-avatar-uploading div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  &-avatar-uploading div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  &-avatar-uploading div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
