@function generate-size($value, $variant: md) {
  $sizes: (
    xs: $value / 4,
    sm: $value / 2,
    md: $value,
    lg: $value * 2,
    xl: $value * 4,
  );
  $fetched-value: map-get($sizes, $variant);
  @if $fetched-value {
    @return #{$fetched-value};
  } @else {
    @error 'Size variant `#{$variant}` not found. Available variants: #{available-names($sizes)}';
  }
}
