.a {
  $font-size: 14px;
  $line-height: 20px;

  & {
    display: inline;
    font-weight: 400;
    font-size: $font-size;
    line-height: $line-height
  }

  &:hover {
    cursor: pointer;
  }

  &-nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
  }
}
