.topbar-alert-container {
  width: 100%;
  margin: 10px auto;
  height: auto;
  padding: 8px 16px;

  &.warning {
    background-color: #FFF8E1;
  }
}

.topbar-alert-content {
  padding: 8px 16px;
  background-color: white;
  color: #455A64;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  margin: 0 auto;

  strong {
    font-weight: bold;
  }

  &-icon {
    svg {
      width: 17px;
      height: 17px;
      margin: 0 4px 2px;
    }
    &.warning svg {
      fill: #FFC107
    }
  }
}

.topbar-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

.main-action {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #2962FF;
  padding: 5px;
  margin: 0 10px;
}

.main-action:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.extra-action {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.3px;
  color: #455A64;
  padding: 5px;
  margin: 0 10px;
}

.extra-action:hover {
  cursor: pointer;
  background-color: #fafafa;
}