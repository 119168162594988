@import "styles/base";

.icon {
  @include fill-color-classes();

  &-default {
    fill: color(ink);
  }

  &-info {
    fill: color(blue);
  }

  &-warning {
    fill: color(orange);
  }

  &-success {
    fill: color(green);
  }

  &-subdued {
    fill: color(sky, dark);
  }

  &-link {
    fill: color(blue);
  }
}
