@import "styles/base";

.card {
  $shadow: shadow();
  $padding: 16px 20px;
  $title-border: border();
  $background: color(white);

  & {
    box-shadow: $shadow;
    background-color: $background;
    border: 2px solid transparent
  }

  &-title {
    padding: $padding;
    border-bottom: $title-border
  }

  &-body {
    padding: $padding;
  }

  &-footer {
    padding: $padding;
    border-top: 1px solid color(sky);
  }

  &-border-none {
    border-color: transparent;
  }

  &-border-blue {
    border-color: color(blue);
  }

  @include padding-classes();
}
