@import 'styles/base';

.input {
  $height: 36px;
  $border: border();
  $radius: border-radius();

  & {
    width: 100%;
    display: flex;
    height: $height;
    border: $border;
    padding: 0 10px;
    font-size: 14px;
    border-radius: $radius;
    box-sizing: border-box;
    border-color: color(sky, dark);
    font-family: 'Open Sans', sans-serif
  }

  &[readOnly] {
    background-color: color(sky, base);
  }

  &:focus {
    outline: none;
    border-color: color(blue)
  }

  &-error {
    border-color: color(red)
  }

  &-select {
    user-select: none;
    cursor: pointer;
  }

  ::placeholder,
  ::-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: color(sky, dark);
  }
}


