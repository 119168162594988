@import "styles/base";

.h3 {
  $padding: 16px;
  $font-size: 26px;
  $line-height: 32px;

  @include margin-classes();
  @include padding-classes();
  @include text-align-classes();

  & {
    font-size: $font-size;
    line-height: $line-height;
  }

  &-body {
    font-size: 14px;
    line-height: 20px;
  }

  &-subdued {
    color: color(ink, lighter);
  }

  &-error {
    color: color(red);
  }

  &-info {
    color: color(blue);
  }
}
