@import 'styles/base';

.footer {

  & {
    background-color: color(white)
  }

  &-icon svg {
    width: 17px;
    height: 17px;
    margin: 0 4px 2px;
  }

  &-content {
    display: flex;
    min-height: 156px;
    align-items: center;
    justify-content: space-between;
  }

  &-body {
    width: fit-content;
  }

  &-copywrite {
    width: 100%;
    text-align: left;
    margin-left: auto;
    white-space: nowrap;
  }

  &-label {
    margin: 0 5px 0;
  }

  @media (max-width: 1280px) {
    padding: 0 16px;
    &-copywrite {
      padding-left: 32px
    }
    &-body {
      margin-right: 32px
    }
  }

  @media (max-width: 795px) {
    & {
      flex-direction: column;
      justify-content: center
    }
    &-body {
      padding: 16px 0;
      justify-content: center
    }
  }

  @media (max-width: 450px) {
    & {
      align-items: center;
    }
    &-content {
      flex-direction: column;
      justify-content: center
    }
    &-body {
      margin: 0;
      align-items: center;
      flex-direction: column
    }
    &-copywrite {
      margin: 0;
      padding: 0;
      text-align: center;
      white-space: normal
    }
  }

}
