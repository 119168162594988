@import "styles/base";

.caption {
  $font-size: 12px;
  $line-height: 16px;

  & {
    font-weight: 400;
    font-size: $font-size;
    line-height: $line-height
  }

  @include padding-classes();
  @include margin-classes();
  @include text-align-classes();
  @include text-color-classes();

  &-subdued {
    color: color(ink, lighter)
  }

  &-error {
    color: color(red)
  }

  &-success {
    color: color(green)
  }

  &-bold {
    font-weight: 700;
  }
}
