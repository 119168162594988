.logo {
  min-height: 24px;
  margin-top: 10px;
}

.blue {
  .name {
    fill: #2494d2;
  }
  .bg1 {
    fill: #057cbd;
  }
  .bg2 {
    fill: #1395d3;
  }
}

.gray {
  .name {
    fill: #d8d8d8;
  }
  .bg1 {
    fill: #bfbfbf;
  }
  .bg2 {
    fill: #d8d8d8;
  }
}

.white {
  .name {
    fill: white;
  }
  .bg1,
  .bg2 {
    fill: white;
  }
  .bg1 {
    opacity: 0.5;
  }
}
