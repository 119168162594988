@import 'styles/base';

.message {
  $padding: 16px;
  $icon-background-color: color(sky);
  $background-color: color(sky, light);
  $border: 3px solid color(ink, lightest);

  & {
    display: flex;
    margin: 16px 0;
    box-shadow: shadow();
    align-items: center;
    border-bottom-left-radius: border-radius();
    border-bottom-right-radius: border-radius();

    padding: $padding;
    border-top: $border;
    background-color: $background-color
  }

  &-body {
    padding: 0 12px
  }

  &-left-icon {
    & {
      width: 32px;
      height: 32px;
      min-width: 32px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: $icon-background-color
    }
    svg {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: color(white);
    }
  }

  &-error {
    border-color: color(red);
    background-color: color(red, lighter)
  }
  &-error &-left-icon {
    background-color: color(red, light);
    svg {
      fill: color(red);
      box-shadow: inset 0 0 0 2px color(red);
    }
  }

  &-success {
    border-color: color(green);
    background-color: color(green, lighter)
  }
  &-success &-left-icon {
    background-color: color(green, light);
    svg {
      fill: color(green);
      box-shadow: inset 0 0 0 2px color(green);
    }
  }
}
