@import 'styles/base';

.card-profile {
    display: flex;
    flex-direction: column;
    max-height: 120px;
    margin-left: 10px;
    min-width: 217px;
    border-radius: 4px;
    background-color: color(sky, lightest);
    justify-content: space-around;
    padding: 13px 8px 0 16px;

    > .head {
      display: flex;
      justify-content: space-between;
      > .small {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: color(ink, lightest);
        margin: 0;
        align-self: center;
      }
    }
    > .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      > .title {
        margin: 0;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        max-width: 179px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: color(black, light);
      }
      > .description {
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: color(ink, text);
        width: 100%;
        margin-bottom: 14px;
      }
    }
}
