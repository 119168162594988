@import 'styles/base';

.badge {
  margin-right: 8px;
  height: 18px;

  & {
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
    padding: 3px 5px;
  }

  &-icon {
    margin-right: 5.5px;

    img {
      display: block;
    }
  }

  &-text {
    font-weight: 600;
    letter-spacing: 0.444444px;
  }

  &-blue {
    background: color(sky, avatar);
    color: #1565c0;
  }

  &-green {
    background: color(green, lighter);
    color: color(green, dark);
  }
  svg {
    width: auto;
  }
}
