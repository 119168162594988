@import 'styles/base';

.select {
  & {
    width: 100%;
    margin: 8px 0;
    user-select: none;
    position: relative
  }

  &-form-field {
    position: relative;
  }

  &-fake-input {
    width: 100%;
    align-items: center;
    height: 36px;
    padding: 0 20px 0 10px;
    font-size: 14px;
    border: border();
    border-color: color(sky, dark);
    box-sizing: border-box;
    border-radius: border-radius();
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 36px;
  }

  &-fake-input:focus  {
    outline: none;
    border-color: color(blue)
  }

  &-form-field-error &-fake-input  {
    border-color: color(red)
  }

  &-trigger {
    position: relative;
    cursor: pointer;
  }

  &-icon {
    & {
      top: 8px;
      right: 1px;
      padding: 0 5px;
      position: absolute;
      background-color: color(white);
      pointer-events: none;
    }
    svg {
      fill: color(ink, lighter)
    }
  }

  &-form-field-error &-icon svg {
    fill: color(red)
  }

  &-menu {
    z-index: 1;
    width: 100%;
    padding: 5px 0;
    position: absolute;
    box-shadow: shadow(deep);
    background-color: color(white)
  }

  &-menu-error {
    top: 60px
  }

  &-options {
    width: 100%;
    overflow-y: auto;
    max-height: 110px;
  }

  &-item {
    & {
      padding: 5px;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      cursor: pointer;
      background-color: color(blue, lighter)
    }
  }

  &-filter {
    padding: 5px;
    padding-top: 0;
    margin-bottom: 5px;
    border-bottom: border()
  }
}
