@import "styles/base";

.list {
  @include margin-classes();

  &-bullet {
    font-size: 32px;
    color: color(blue)
  }
}
