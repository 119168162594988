@import 'styles/base';

.radio-input {
  &-wrapper {
    display: flex;
    align-items: center;
  }

  &-hide {
    display: none
  }

  input[type='radio'] ~ &-element {
    & {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      position: relative;
      border-radius: 50%;
      box-shadow: shadow(faint);
      border: 1px solid #c4cdd5;
      background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
    }
  }

  &:hover &-element {
    border: 1px solid color(blue);
  }

  input[type='radio']:checked ~ &-element {
    & {
      box-shadow: shadow(faint);
      border: 1px solid color(blue);
    }
    &::after {
      top: 4px;
      left: 4px;
      content: '';
      width: 8px;
      height: 8px;
      display: block;
      position: absolute;
      border-radius: 50%;
      background-color: color(blue);
    }
  }
}
