@mixin button-gradient($color, $variant: base) {
  $button-color: color($color, $variant);
  $focus-color: color($color, dark);
  $border-color: darken($button-color, 10%);
  $active-color: darken($button-color, 15%);

  & {
    color: color(white);
    border-color: $border-color;
    box-shadow: inset 0 1px 0 0 lighten($button-color, 3%), shadow(faint), 0 0 0 0 transparent;
    background: linear-gradient(to bottom, lighten($button-color, 2%), darken($button-color, 2%))
  }

  &:hover {
    color: color(white);
    text-decoration: none;
    border-color: $border-color;
    background: linear-gradient(to bottom, $button-color, darken($button-color, 5%))
  }
  &:focus {
    border-color: $focus-color;
    box-shadow: inset 0 1px 0 0 lighten($button-color, 5%), shadow(faint), 0 0 0 1px $focus-color
  }
  &:active {
    border-color: $active-color;
    background: linear-gradient(to bottom, $border-color, $border-color);
    box-shadow: inset 0 0 0 0 transparent, shadow(faint), 0 0 1px 0 $active-color
  }
  &:disabled, &[disabled] {
    box-shadow: none;
    color: color(white);
    border-color: lighten($button-color, 20%);
    background: linear-gradient(to bottom, lighten($button-color, 25%), lighten($button-color, 25%))
  }
}
