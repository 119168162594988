@import 'styles/base';

.textarea-field {
  & {
    width: 100%;
    margin: 5px
  }

  &:first-child {
    margin-left: 0
  }

  &:last-child {
    margin-right: 0
  }
}

.textarea {
  $border: border();
  $radius: border-radius();

  & {
    width: 100%;
    display: flex;
    padding: 10px;
    font-size: 14px;
    border: $border;
    resize: vertical;
    min-height: 128px;
    border-radius: $radius;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif
  }

  &:focus {
    outline: none;
    border-color: color(blue)
  }

  &-error {
    border-color: color(red)
  }

  &-select {
    user-select: none;
    cursor: pointer;
  }

  ::placeholder {
    color: color(sky, dark);
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: color(sky, dark);
  }
  ::-ms-input-placeholder {
    color: color(sky, dark);
  }
}
