@import 'styles/base';

.icon-button {
  // Button
  $width: 36px;
  $height: 36px;
  $cursor: pointer;
  $shadow: shadow();
  $border: border();
  $user-select: none;

  & {
    padding: 0 8px;
    display: flex;
    border: none;
    cursor: $cursor;
    width: $width;
    height: $height;
    align-items: center;
    justify-content: center;
    user-select: $user-select;
    line-height: 20px
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default
  }
}
