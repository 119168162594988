.modal {
  & {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1000;
    position: fixed;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    overflow-y: auto;
  }
  &-content {
    z-index: 11;
    position: relative;
    min-width: 290px;
    max-height: 100%;
  }
  &-lg {
    .modal-content {
      min-width: 660px;
    }
  }
  &-overlay {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0, 0.5);
  }
  &-footer {
    background: #ccc;
  }
  &-clear {
    position: absolute;
    right: 12px;
    top: 12px;
  }
  &-responsive {
    @media (max-width: 620px) {
      & {
        right: 0;
        bottom: 0;
        position: fixed;
        overflow-y: scroll;
        align-items: flex-start;
      }
      .modal-content {
        min-width: 260px;
        width: 90vw;
      }
    }
  }
}
