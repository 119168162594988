@import "styles/base";

.flex {
  & {
    display: flex
  }

  &-wrap {
    flex-wrap: wrap
  }

  &-nowrap {
    flex-wrap: nowrap
  }

  &-col {
    flex-direction: column
  }

  &-row {
    flex-direction: column
  }

  &-justify-center {
    justify-content: center
  }

  &-justify-start {
    justify-content: flex-start
  }

  &-justify-end {
    justify-content: flex-end
  }

  &-justify-between {
    justify-content: space-between
  }

  &-justify-around {
    justify-content: space-around
  }

  &-items-center {
    align-items: center
  }

  &-items-start {
    align-items: flex-start
  }

  &-items-end {
    align-items: flex-end
  }

  @include padding-classes();
  @include margin-classes();
}
