@import "styles/base";

.link {
  &-default {
    & {
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      box-shadow: none;
      color: color(blue);
      border-color: transparent;
      background-color: transparent
    }
    &:hover {
      color: color(blue, dark)
    }
    &:focus {
      outline: none;
      color: color(blue, dark);
      background-color: color(sky)
    }
    &[disabled]:hover {
      color: color(blue, dark)
    }
  }

  &-success {
    @include button-gradient(green)
  }

  svg {
    margin: 0 5px;
    width: 14px;
    fill: color(blue);
  }
}
