@import "./property-position.mixin";

@mixin size-group($prefix, $property, $value, $position: default) {
  $selector: #{$prefix};
  @if $position != default {
    $selector: #{$prefix}-#{$position};
  }

  &-#{$selector} {
    &-xs {
      @include property-position($property, $position, generate-size($value, xs))
    }
    &-sm {
      @include property-position($property, $position, generate-size($value, sm))
    }
    & {
      @include property-position($property, $position, generate-size($value))
    }
    &-lg {
      @include property-position($property, $position, generate-size($value, lg))
    }
    &-xl {
      @include property-position($property, $position, generate-size($value, xl))
    }
  }
}
