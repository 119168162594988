.terms {
  &-body {
    height: 50vh;
    max-width: 500px;
    overflow-y: auto;
    text-align: left;
    min-height: 300px;
  }
  &-trigger {
    cursor: pointer;
  }
}
