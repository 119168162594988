@import "styles/base";

.progress {
  & {
    width: 100%;
    height: 7px;
    position: relative;
    border-radius: 99999px;
    background-color: color(sky)
  }

  &-bar {
    @include bg-color-classes();

    & {
      height: 7px;
      display: block;
      border-radius: 99999px
    }
  }
}
