@import "styles/base";

.h4 {
  $padding: 16px;
  $font-size: 20px;
  $line-height: 24px;

  & {
    font-weight: 600;
    font-size: $font-size;
    line-height: $line-height
  }

  @include padding-classes();
  @include margin-classes();
  @include text-align-classes();

  &-subdued {
    color: color(ink, lighter)
  }

  &-error {
    color: color(red)
  }

  &-info {
    color: color(blue)
  }
}
