@import 'styles/base';

.switch-form {
    display: flex;
    margin-top: 16px;

    & button {
      min-width: 116px;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 36px;
      text-align: center;
      outline: none;
      color: #90A4AE;
      background: color(white, base);
      border: 1px solid #CFD8DC;
      outline: none !important;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .selected {
    color: #2979FF !important;
    border: 1px solid #82B1FF !important;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  @media (min-width: 321px) and (max-width: 500px) {
    .switch {
      width: 100%;
      max-width: 232px;

      & button {
        width: 50%;
      }
    }
  }
