.notifications {
  & {
    left: 5px;
    bottom: 0;
    z-index: 10000;
    max-width: 50%;
    min-width: 300px;
    position: fixed;
  }
}
